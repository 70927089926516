<template>
  <div id="container_home">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->

      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          HISTORY
        </h2>
        <h2 class="company_tit animated d-2s on">
          SINCE 1998, 오늘보다 더 나은 내일을 위해 달려갑니다.
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/company_03.png" class="animated d-1s" alt="">
      </div>

    </div>
    <div class="sub_wrap">
			<div class="company_inner">
    <div class="history_section">
      <div class="default_section">
        <div class="history_wrap">
          <div class="history_title sub_wrap_tit animated">
            <div class="sub">이트너스가 걸어온 발자취</div>
            <div class="eg">1998~현재</div>
          </div>
          <!--//히스토리타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h30.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2022</div>
                <div class="tit">벤처천억 기업 수상</div>
                <div class="tit">삼성전자 B2B 스타파트너 4STAR 수상</div>
                <div class="tit">ESOP워크 분당서현역센터 오픈</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h12.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2021</div>
                <div class="tit">중소기업인 대회 산업포장 수훈</div>
                <div class="tit">청년친화강소기업 선정</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-2s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h13.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2020</div>
                <div class="tit">가족친화우수기업 대통령 표창 수상</div>
                <div class="tit">과학기술정보통신부 지정 우수기업연구소 선정 </div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-3s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h14.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2019</div>
                <div class="tit">제52회 성남상공대상 기업경영대상 수상</div>
                <div class="tit">제43회 국가생산성대상 국무총리표창 수상</div>
                <div class="tit">여성가족부 가족친화기업 인증</div>
                <div class="tit">이트너스 근태관리 솔루션 개발 </div>
                <div class="tit">이트너스 사무비품관리 솔루션 개발 </div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h15.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2015</div>
                <div class="tit">일자리 창출 유공 대통령 표창 수상</div>
                <div class="tit">과학기술진흥 유공 표창 수상</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h16.png" alt=""></div>
              <div class="text">
                <div class="dh_gray">1998</div>
                <div class="tit">이트너스 설립</div>
              </div>
            </li>
          </ul>
        </div>
        <!---//히스토리요약--->
        <!--전체보기버튼-->
        <a href="javascript:void(0)" class="btn_all" onclick="toggleSeeAll()">연혁 전체보기</a>
        <!--//전체보기버튼-->
      </div>
      <div class="see_all_section">
        <!---히스토리전체보기--->
        <!--히스토리1-->
        <div class="history_wrap animated">
          <!--히스토리1타이틀-->
          <div class="history_title sub_wrap_tit animated d-1s">
            <div class="sub">경영지원플랫폼
              전문기업을 향하여</div>
            <div class="eg">2018~현재</div>
          </div>
          <!--//히스토리1타이틀-->
          <ul class="history_list animated">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h30.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2022</div>
                <div class="sub gray1">벤처천억 기업 수상</div>
                <div class="sub gray1">ESOP워크 분당서현역센터 오픈</div>
                <div class="sub gray1">ESOP에듀 동탄센터 오픈</div>
                <div class="sub gray1">ESOP에듀 평택센터 오픈</div>
                <div class="sub gray1">삼성전자 B2B 스타파트너 4STAR 수상</div>
                <div class="sub gray1">삼성전자 스타파트너스데이 우수스타상 수상</div>
                <div class="sub gray1">삼성전자 스타파트너스데이 장기경영상(10년) 수상</div>
              </div>

            </li>
            <li class="animated d-2s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h02.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2021</div>
                <div class="sub gray1">중소기업인 대회 산업포장 수훈</div>
                <div class="sub gray1">선물 큐레이션 서비스 감동타임 오픈</div>
                <div class="sub gray1">청년친화강소기업 선정</div>
                <div class="sub gray1">공유오피스 ESOP( ETNERS Smart Office Platform) SAC 센터 오픈</div>
              </div>
            </li>
            <li class="animated d-3s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h03.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2020</div>
                <div class="sub gray1">가족친화우수기업 대통령 표창 수상</div>
                <div class="sub gray1">과학기술정보통신부
                  지정 우수기업연구소 선정</div>
                <div class="sub gray1">공유오피스 ESOP( ETNERS Smart Office Platform) 테라센터 오픈</div>
                <div class="sub gray1">통합
                  물류 운영 솔루션 EFS( ETNERS Fulfillment Solution) 신규 사업장 오픈</div>
              </div>
            </li>
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h04.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2019</div>
                <div class="sub gray1">제52 회 성남상공대상 기업경영대상 수상</div>
                <div class="sub gray1">제43 회 국가생산성대상 국무총리표창 수상</div>
                <div class="sub gray1">여성가족부 가족친화기업 인증</div>
                <div class="sub gray1">이트너스 근태관리 솔루션 개발 </div>
                <div class="sub gray1">이트너스 사무비품관리 솔루션 개발</div>
              </div>
            </li>
            <li class="animated d-5s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h20.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2018</div>
                <div class="sub gray1">외국인 정착지원 쇼핑몰 ETNERS HOUSINGSHOP 오픈 </div>
                <div class="sub gray1">주재원 복지쇼핑몰 ETNERSMALL2.0 리뉴얼 </div>
                <div class="sub gray1">성남시 의료관광 협력기관 지정</div>
                <div class="sub gray1">국가관 구매 및 배송 특화 쇼핑몰 특허출원</div>
                <div class="sub gray1">QR코드 활용 박스내부 확인 시스템 특허 출원 </div>
                <div class="sub gray1">국제표준품질경영시스템 ISO9001 인증</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리1-->
        <!--히스토리2-->
        <div class="history_wrap">
          <!--히스토리2타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">새로운 내일을 향한 도전</div>
            <div class="eg">2012~2017</div>
          </div>
          <!--//히스토리2타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h06.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2017</div>
                <div class="sub gray1">Samsung B2B Star Partner 선정</div>
              </div>

            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h07.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2016</div>
                <div class="sub gray1">여성고용 우수기업 선정</div>
                <div class="sub gray1">사업자전용 온라인 마켓 Etners Shop 오픈</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h08.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2015</div>
                <div class="sub gray1">일자리 창출 유공 대통령 표창 수상</div>
                <div class="sub gray1">과학기술진흥 유공 표창 수상</div>
                <div class="sub gray1">통합 물류운영 솔루션 Etners DMS개발 </div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h09.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2014</div>
                <div class="sub gray1">인적자원개발 우수기관 인증 </div>
                <div class="sub gray1">스마트워크 우수사례 장려상 수상 </div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h17.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2013</div>
                <div class="sub gray1">경기도 일하기 좋은 기업 선정 </div>
                <div class="sub gray1">총무닷컴 인수</div>
                <div class="sub gray1">기업자산 매각 솔루션 변경 </div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h19.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2012</div>
                <div class="sub gray1">기업혁신대상 국무총리상 수상</div>
                <div class="sub gray1">매일경제 취업하고 싶은 기업 선정</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리2-->
        <!--히스토리3-->
        <div class="history_wrap">
          <!--히스토리3타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">SMART WORK의 실현</div>
            <div class="eg">2007~2011</div>
          </div>
          <!--//히스토리3타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h05.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2011</div>
                <div class="sub gray1">사택관리 솔루션 ETNERS Housing 개발</div>
                <div class="sub gray1">글로벌 이사 전문 솔루션 ETNERS Moving 개발</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h21.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2010</div>
                <div class="sub gray1">자산관리 솔루션 ETNERS Asset 개발</div>
                <div class="sub gray1">주재원 복지쇼핑몰 ETNERS Mall 개발</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h22.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2009</div>
                <div class="sub gray1">이트너스 디자인 설립</div>
                <div class="sub gray1">기업부설연구소 설립 </div>
                <div class="sub gray1">新우리사주제도 도입</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h18.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2008</div>
                <div class="sub gray1">총무 전문서적 실무중심의 전략적 총무 발간</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h26.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2007</div>
                <div class="sub gray1">기술 혁신형 중소기업 인증 </div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리3-->
        <!--히스토리4-->
        <div class="history_wrap">
          <!--히스토리4타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">경영의 새로운 패러다임 제시</div>
            <div class="eg">1998~2006</div>
          </div>
          <!--//히스토리4타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h24.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2005</div>
                <div class="sub gray1">한국 경제 경영혁신 우수기업 선정</div>
                <div class="sub gray1">B2B 특판 서비스 시작 </div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h28.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2004</div>
                <div class="sub gray1">인트라넷 Utopia 개발 </div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h27.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2003</div>
                <div class="sub gray1">페이롤 서비스 시작 </div>
                <div class="sub gray1">외국인 지원 서비스 GHD(Global Help Desk) 시작</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h25.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2002</div>
                <div class="sub gray1">사무환경 서비스 시작</div>
                <div class="sub gray1">자산실사 서비스 시작</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h16.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">1998</div>
                <div class="sub gray1">이트너스 설립</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리4-->
        <!---//히스토리전체보기--->
        <!--닫기버튼--><a href="javascript:void(0)" class="btn_all" onclick="toggleSeeAll()">닫기</a>
        <!--닫기버튼-->
      </div>
    </div>
  </div>
</div>
</div>

</div>
<!-- //history -->
</div>
</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'CompanyHistory',
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>
