<template>
  <div id="container">
    <div class="title_wrap">
      <h1>약관관리</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li class="col3">
              <dd>
                <label>카테고리</label>
                <ul>
                  <li>
                    <kendo-dropdownlist ref="solCdRef"
                                        :data-source="$store.state.codeRequestStore.search.combobox.termsSolCd.data" 
                                        :data-text-field="'cdNmTx'"
                                        :data-value-field="'cdId'" 
                                        :option-label="'전체'" 
                                        :index="0" :style="'width: 100%;'"
                                        :value="lastRouteParams.termsSolCd"
                                        :filter="'contains'"
                                        @change="commonOnChangeEvent">
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>분류</label>
                <ul>
                  <li>
                    <kendo-dropdownlist ref="termsCdRef" 
                                        :value="lastRouteParams.termsCd"
                                        :data-source="$store.state.codeRequestStore.search.combobox.termsCd.data"
                                        :data-text-field="'cdNmTx'" 
                                        :data-value-field="'cdId'" 
                                        :style="'width: 100%'" 
                                        :option-label="'전체'"
                                        @change="commonOnChangeEvent">
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>적용 날짜</label>
                <ul>
                  <li>
                    <div class="fl k-widget k-daterangepicker k-state-default w100" 
                         data-role="daterangepicker"
                         role="combobox" 
                         aria-expanded="false" 
                         autocomplete="off">
                      <kendo-datepicker ref="termsStartDtRef" 
                                        :placeholder="'년/월/일'" 
                                        :format="'yyyy/MM/dd'"
                                        @change="commonOnChangeEvent">
                      </kendo-datepicker>
                      <span>&nbsp;</span>
                      <kendo-datepicker ref="termsEndDtRef" 
                                        :placeholder="'년/월/일'" 
                                        :format="'yyyy/MM/dd'"
                                        @change="commonOnChangeEvent">
                      </kendo-datepicker>
                    </div>
                  </li>
                </ul>
              </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div style="margin-top: 10px">

        </div>
        <div class="contents_top_sec">
          <button class="mid_btn orange fl" @click="onClickCreateTerms">
            <span class="k-icon k-i-plus"></span> 약관 등록
          </button>
        </div>
        <kendo-grid ref="termsListGridRef" 
                    style="cursor: pointer;" 
                    :data-source="computedTermsListDataSource"
                    :columns="termsGridRefColumns" 
                    :navigatable="true" 
                    :filterable="false" 
                    :sortable="true" 
                    :scrollable="true"
                    :pageable-always-visible="true" 
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]" 
                    :reorderable="true"
                    :column-menu="false" 
                    :resizable="true" 
                    :selectable="'multiple row'"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
        </kendo-grid>
        <terms-manage-window ref="termsWindowRef" 
                              :rowData="rowData"
                              :visible="false"
                              @onClickWindowClose2="onClickWindowClose">
        </terms-manage-window>
      </div>
    </section>
  </div>
</template>
<script>
import ApiConfig from '@/api/api.config'
import TermsManageWindow from '@/components/TermsManageWindow'
import EtnersCommonUtil from '@/common/etners.common.util'
import ApiUtil from '../../../api/api.util'

export default {
  name: 'TermsManagement',
  beforeMount() {
    if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'TermsManagement' })
  },
  components: {
    TermsManageWindow
  },
  created() {
    const vm = this

    const jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
    vm.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'termsDetail' ? (jsonStrLastRouteParams === undefined ? [] : JSON.parse(jsonStrLastRouteParams)) : []
    this.$store.dispatch('initSearchDataForCodeRequest')
  },
  mounted() {
    const vm = this

    //엔터키 이벤트 자동 세팅
    const enterkeyEventTargetIdArray = []

    for (const elementId of enterkeyEventTargetIdArray) {
      const element = document.getElementById(elementId)
      if (element !== undefined) {
        element.onkeyup = function (e) {
          const ENTER_KEY_CODE = 'Enter'
          const NUM_PAD_ENTER = 'NumpadEnter'
          const currentKeyCode = e.code
          if (currentKeyCode === ENTER_KEY_CODE || currentKeyCode === NUM_PAD_ENTER) {
            vm.commonOnChangeEvent(e)
          }
        }
      }
    }
  },
  computed: {
    //termsList 데이터 호출
    computedTermsListDataSource: function () {
      const vm = this
      let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

      const termsListDataSource = new kendo.data.DataSource({
        name: 'termsListDataSource',
        page: lastRequestParameterPage,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.ethDomain}/v1/eth/terms/termsList.json`,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {
            if (operation === 'read') {
              const filter = vm.currentPageFilterOption()
              let loginUserToken = sessionStorage.adminLoginUserToken

              let params = {
                loginUserToken: loginUserToken,
                searchSolCd: filter.termsSolCd,
                searchTermsCd: filter.termsCd,
                searchStartDt: filter.termsStartDt,
                searchEndDt: filter.termsEndDt,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                sort: options.sort,

                requestType: 'PAGING'
              }

              sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

              return kendo.stringify(params)
            }
            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          }
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = response.total + 1 - (vm.$refs.termsListGridRef.dataSource.page() - 1) * vm.$refs.termsListGridRef.dataSource.pageSize()
            if (changeResultData != null) {
              for (let i = 0; i < changeResultData.length; i++){
                let row = changeResultData[i]
                let termsId = changeResultData[i].termsId
                row.termsId = termsId
                row.rowNumber = --rowNumber;
              }
              response.resultData = changeResultData
            }
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            } else if (response.resultStatus.messageCode === '4204') {
              return next("/homeadmin/login")
            } else {
              kendo.alert('message: ' + response.resultStatus.messageText)
              return ''
            }
          }
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return termsListDataSource
    }
  },
  methods: {
    // onOpenWindow() {
    //   const vm = this
    //   // vm.termsRowData.termsCdCd = vm.termsFileData.termsCdreplaceField
    //   // console.log("vm.termsRowData.termsCd", vm.termsFileData.termsCd)
    //   vm.termsFileData = vm.termsFileData
    // },

    //초기화 버튼
    cleanSearchDataEvent(e) {
      const refs = this.$refs
      const vm = this

      //termsSolCd
      if (refs.solCdRef !== undefined) {
        refs.solCdRef.kendoWidget().select(0)
      }
      //termsCd
      if (refs.termsCdRef !== undefined) {
        refs.termsCdRef.kendoWidget().select(0)
      }
      if (refs.termsStartDtRef !== undefined) {
        refs.termsStartDtRef.kendoWidget().value('')
      }
      if (refs.termsEndDtRef !== undefined) {
        refs.termsEndDtRef.kendoWidget().value('')
      }
      vm.commonOnChangeEvent()
    },
    commonOnChangeEvent: function () {
      const vm = this
      let gridRef = vm.$refs.termsListGridRef

      // 마지막 검색 데이터 page 초기화
      this.lastRouteParams.page = 1

      if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
        if (gridRef.kendoWidget().dataSource !== undefined) {
          gridRef.kendoWidget().dataSource.page(1)
        }
      }
    },

    // 새로운 등록
    onClickCreateTerms: function (e) {
      const vm = this
      vm.rowData = {
        termsSolCd: '',
        solTx: '',
        termsCd: '',
        useYn: 'Y',
        termsContentsTx: '',
        termsContents: '',
        startDt: '',
        endDt: '',
      }
      vm.$refs.termsWindowRef.$refs.termsRef.kendoWidget().center().open()
    },
    onClickCopyCreateTerms: function (e) {
      const vm = this
      const rowDataItem = this.$refs.termsListGridRef.kendoWidget().dataItem(e.currentTarget.parentElement.parentElement)
      rowDataItem.copyYn = "Y"
      vm.rowData = rowDataItem
      vm.$refs.termsWindowRef.$refs.termsRef.kendoWidget().center().open()
    },
    onClickUpdateTerms: function (e) {
      const vm = this
      const rowDataItem = this.$refs.termsListGridRef.kendoWidget().dataItem(e.currentTarget.parentElement.parentElement)
      rowDataItem.updateYn="Y"
      vm.rowData = rowDataItem
      vm.$refs.termsWindowRef.$refs.termsRef.kendoWidget().center().open()
    },
    currentPageFilterOption: function () {
      const vm = this
      const refs = vm.$refs

      const currentSearchDataObj = {}

      //카테고리
      if (refs.solCdRef !== undefined) {
        const termsSolCd = refs.solCdRef.kendoWidget().value()
        if (termsSolCd !== undefined && termsSolCd !== '') {
          currentSearchDataObj.termsSolCd = termsSolCd
        }
      }
      //등록자
      if (refs.termsCdRef !== undefined) {
        const termsCd = refs.termsCdRef.kendoWidget().value()
        if (termsCd !== undefined && termsCd !== '') {
          currentSearchDataObj.termsCd = termsCd
        }
      }
      //첫 적용 날짜
      if (refs.termsStartDtRef !== undefined) {
        const termsStartDt = refs.termsStartDtRef.kendoWidget().value()
        if (termsStartDt !== undefined && termsStartDt !== '') {
          currentSearchDataObj.termsStartDt = termsStartDt
        }
      }
      //마지막 적용 날짜
      if (refs.termsEndDtRef !== undefined) {
        const termsEndDt = refs.termsEndDtRef.kendoWidget().value()
        if (termsEndDt !== undefined && termsEndDt !== '') {
          currentSearchDataObj.termsEndDt = termsEndDt
        }
      }
      return currentSearchDataObj
    },
    onClickDeleteTerms(e) {
      const vm = this
      const rowDataItem = this.$refs.termsListGridRef.kendoWidget().dataItem(e.currentTarget.parentElement.parentElement)
      kendo.confirm('정말 삭제하겠습니까?').done(function () {
        const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsDelete.json`
        const param = {
          termsId:rowDataItem.termsId
        }
        ApiUtil.post(url, param).then(response => {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            this.$Progress.fail()
            return false
          }
          const resultStatus = response.data.resultStatus

          if (resultStatus.messageCode !== '2000') {
            kendo.alert(resultStatus.messageText)
            return false
          }
          kendo.alert('삭제되었습니다.')
          vm.$refs.termsListGridRef.kendoWidget().dataSource.read()
        })
      })
    },
    onClickWindowClose() {
      const vm = this
      // vm.initRowData()
      // vm.$refs.termsWindowRef.$refs.termsRef.kendoWidget().close()
      this.$refs.termsListGridRef.kendoWidget().dataSource.read()
    },
    initRowData() {
      const vm = this
      vm.rowData.termsSolCd = ''
      vm.rowData.solTx = ''
      vm.rowData.termsCd = ''
      vm.rowData.useYn = 'Y'
      vm.rowData.termsContentsTx = ''
      vm.rowData.termsContents = ''
      vm.rowData.startDt = ''
      vm.rowData.termsStartDt = ''
      vm.rowData.endDt = ''
    },
  },
  data: function () {
    const kendoGridHeaderAttributes = {
      style: 'font-weight: bold; text-align : center;'
    }
    const vm = this
    return {
      // termsRowData: {},
      result: {},
      rowData: {},
      termsFileData: {},
      listDataSource: [],
      lastRouteParams: [],
      loginUserToken: sessionStorage.adminLoginUserToken,
      termsGridRefColumns: [
        {
          field: 'solTx',
          title: '카테고리',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
        },
        {
          field: 'termsTx',
          title: '구분',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
        },
        {
          field: 'termsStartDt',
          title: '적용 일자',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
          // template(dataItem) {
          //   return dataItem.startDt + '  ~  ' + dataItem.endDt
          // }
        },
        {
          field: 'termsEndDt',
          title: '만료 일자',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
          // template(dataItem) {
          //   return dataItem.startDt + '  ~  ' + dataItem.endDt
          // }
        },
        {
          title: '복사',
          command: [
            {
              text: '복사',
              template: kendo.template('<a role=\'button\' class=\'mid_btn k-button k-button-icontext k-grid-복사\'>복사</a>'),
              click: this.onClickCopyCreateTerms,
            },
          ],
          width: '3%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
          sortable: false
        },
        {
          field: '',
          title: '보기',
          width: '3%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
          command: [{
            name: "terms-detail",
            template: `<a role=\'button\' class="mid_btn k-grid-terms-detail">보기</a>`,
            click: function (e) {
              e.preventDefault();
              let tr = $(e.target).closest('tr')
              const selectedRow = this.dataItem(tr)
              // vm.$router.push({
              //   name: 'TermsDetail',
              //   params: {
              //     termsSolCd: selectedRow.termsSolCd,
              //     termsCd: selectedRow.termsCd,
              //     termsId: selectedRow.termsId
              //   }
              // })
              window.open(`/terms/management/detail/${selectedRow.termsSolCd}/${selectedRow.termsCd}`)
            }
          }]
        },  
        {
          title: '수정',
          command: [
            {
              text: '수정',
              template: kendo.template('<a role=\'button\' class=\'mid_btn k-button k-button-icontext k-grid-수정\'>수정</a>'),
              click: this.onClickUpdateTerms,
            },
          ],
          width: '3%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
          sortable: false
        },
         {
           title: '삭제',
          command: [
            {
              text: '삭제',
              template: kendo.template('<a role=\'button\' class=\'mid_btn k-button k-button-icontext k-grid-삭제\'>삭제</a>'),
              click: this.onClickDeleteTerms,
            },
          ],
          width: '3%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
          sortable: false
        },
        {
          field: 'frstRgstId',
          title: '등록자',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
        },
        {
          field: 'frstRgstDt',
          title: '등록일자',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
        },
        {
          field: 'useYn',
          title: '사용유무',
          width: '6%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter'
          },
        },
      ]
    }
  }
}
</script>

<style scoped></style>