<template>
  <div id="container">
    <div class="title_wrap">
      <h1>특허 · 인증</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>
              <dd>
                  <label>취득 · 인증날짜</label>
                  <ul class="w60">
                      <kendo-daterangepicker
                              ref="patentCertDtRef"
                              :class="'w60 fl mr5'"
                              :format="'yyyy/MM/dd'"
                              :range="patentCertDtRange"
                              @change="commonOnChangeEvent"
                      >
                      </kendo-daterangepicker>

                  </ul>
              </dd>
              <dd>
                  <label>날짜</label>
                  <ul class="w60">
                      <kendo-daterangepicker
                              ref="lastMdfyDtRef"
                              :class="'w60 fl mr5'"
                              :format="'yyyy/MM/dd'"
                              :range="rgstDtRange"
                              @change="commonOnChangeEvent"
                      >
                      </kendo-daterangepicker>

                  </ul>
              </dd>
            </li>
            <li>
              <dd>
                  <label>특허 · 인증명</label>
                  <ul class="w60">
                    <kendo-maskedtextbox
                            ref="patentNmTxRef"
                            id="patentNmTx"
                            :style="'width: 100%;'"
                            :value="lastRouteParams.filter.searchPatentNmTx">
                    </kendo-maskedtextbox>
                  </ul>
              </dd>
              <dd>
                  <label>작성자</label>
                  <ul class="w60">
                    <kendo-maskedtextbox
                            ref="lastMdfyIdRef"
                            id="lastMdfyId"
                            :style="'width: 100%;'"
                            :value="lastRouteParams.filter.searchUserNmKoTx">
                    </kendo-maskedtextbox>
                  </ul>
              </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn orange fl" @click="onClickCreateNewPatent">
            <span class="k-icon k-i-plus"></span> 특허 · 인증 등록
          </button>
        </div>
        <kendo-grid
            ref="patentListGridRef"
            style="cursor: pointer;"
            :data-source="computedPatentListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="true"
            :sortable="true"
            :columns="patentGridRefColumns"
            :dataBound="dataBoundForPatent"
            @change="onChangePatentListGrid"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>

        <kendo-window :title="editMode == 'INSERT' ? '특허 · 인증 등록' : '특허 · 인증 수정'" 
                      ref="patentRef" 
                      :modal="true" 
                      :width="'1000px'" 
                      :height="'70%'" 
                      :visible="false"
                      style="box-sizing:border-box;">
                     
          <patent-manage-window ref="patentWindowRef"
                                :patentRowData="patentRowData"
                                :patentCertificationFileData="patentCertificationFileData"
                                :patentThumbnailFileData="patentThumbnailFileData"
                                :editMode='editMode'
                                @editModeUpdate="editModeUpdate"
                                @onClickWindowClose="onClickWindowClose">
          </patent-manage-window>
        </kendo-window>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersKendoGridUtil from '@/common/kendo.grid.util'
  import EtnersParseUtil from '@/common/etners.parse.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  //import { saveExcel } from '@progress/kendo-vue-excel-export'
  import PatentManageWindow from '@/components/PatentManageWindow'

  import $ from 'jquery'

  export default {
    name: 'PatentManagement',
    components:{
      PatentManageWindow
    },
    beforeMount() {
      if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'HomeQnaManagement' })
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'patentDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
         'patentNmTx', 'lastMdfyId'
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }
      this.$refs.lastMdfyDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      })
      this.$refs.lastMdfyDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      })
      this.$refs.patentCertDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      })
      this.$refs.patentCertDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      })
    },
    computed: {
      computedPatentListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const patentListDataSource = new kendo.data.DataSource({
          name: 'patentListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/post/patentList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken: loginUserToken,
                  searchLastMdfyId: filter.lastMdfyId,
                  searchPatentNmTx: filter.patentNmTx,
                  searchLastMdfyDtStartYYYYMMDD : filter.searchLastMdfyDtStartYYYYMMDD,
                  searchLastMdfyDtEndYYYYMMDD : filter.searchLastMdfyDtEndYYYYMMDD,
                  searchPatentCertDtStartYYYYMMDD : filter.searchPatentCertDtStartYYYYMMDD,
                  searchPatentCertDtEndYYYYMMDD : filter.searchPatentCertDtEndYYYYMMDD,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.patentListGridRef.dataSource.page() - 1)
                    * vm.$refs.patentListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    let patentId = changeResultData[i].patentId
                    row.patentId = patentId
                    row.rowNumber = --rowNumber;
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else if (response.resultStatus.messageCode ==='4204'){
                return next("/homeadmin/login")
              }else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return patentListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.patentListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter

        //등록자
        if (refs.lastMdfyIdRef !== undefined) {
          const searchLastMdfyId = refs.lastMdfyIdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchLastMdfyId)) {
            currentSearchDataObj.lastMdfyId = searchLastMdfyId
          }
        }

        if (refs.patentNmTxRef !== undefined) {
          const searchPatentNmTx = refs.patentNmTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchPatentNmTx)) {
            currentSearchDataObj.patentNmTx = searchPatentNmTx
          }
        }

        // 요청일자
        if (refs.lastMdfyDtRef !== undefined) {
          const rgstDtRangeValue = refs.lastMdfyDtRef.kendoWidget().range()

          if (EtnersCommonUtil.isNotEmpty(rgstDtRangeValue)) {
            const startYYYYMMDD = (rgstDtRangeValue.start !== undefined && rgstDtRangeValue.start !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.start)
                                : undefined
            const endYYYYMMDD = (rgstDtRangeValue.end !== undefined && rgstDtRangeValue.end !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.end)
                                : undefined

            currentSearchDataObj.searchLastMdfyDtStartYYYYMMDD = startYYYYMMDD
            currentSearchDataObj.searchLastMdfyDtEndYYYYMMDD = endYYYYMMDD
          }
        }

        if (refs.patentCertDtRef !== undefined) {
          const patentCertDtRangeValue = refs.patentCertDtRef.kendoWidget().range()

          if (EtnersCommonUtil.isNotEmpty(patentCertDtRangeValue)) {
            const startYYYYMMDD = (patentCertDtRangeValue.start !== undefined && patentCertDtRangeValue.start !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(patentCertDtRangeValue.start)
                                : undefined
            const endYYYYMMDD = (patentCertDtRangeValue.end !== undefined && patentCertDtRangeValue.end !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(patentCertDtRangeValue.end)
                                : undefined

            currentSearchDataObj.searchPatentCertDtStartYYYYMMDD = startYYYYMMDD
            currentSearchDataObj.searchPatentCertDtEndYYYYMMDD = endYYYYMMDD
          }
        }

        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      onClickCreateNewPatent: function (e) {
        const vm = this
        vm.editMode = 'INSERT'
        vm.patentRowData.patentId = ''
        vm.patentRowData.patentNmTx = ''
        vm.patentRowData.patentCertNoTx = ''
        vm.patentRowData.fmtPatentCertDt = ''
        vm.patentRowData.patentYn = ''
        vm.uploads = {}
        vm.patentCertificationFileData = ''
        vm.patentThumbnailFileData = ''
        let patentManageWindow = this.$refs.patentRef.kendoWidget()
        patentManageWindow.center().open()

      },
      dataBoundForPatent: function (evt) {
         let vm = this
         let isDragging = false;

         $(".k-grid td")
         .mousedown(function() {
            $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
            });
         })
         .mouseup(function() {
            let wasDragging = isDragging;
            isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging) {
              let row = evt.sender.selectable.userEvents.currentTarget
              let selectedRow = evt.sender.select();
            }
         });
     },
      //row 클릭 이벤트
      onChangePatentListGrid: function (e) {
        const vm = this

        let selectedRow = e.sender.select();
        const rowData = e.sender.dataItem(selectedRow);
        vm.editMode = 'DETAIL'

        let param = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          patentId: rowData.patentId,
        }

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/patentDetail.json`, param).then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.patentRowData = response.data.resultData.tbPatentMst
              vm.patentRowData.fmtLastMdfyDt = rowData.fmtLastMdfyDt
              vm.patentRowData.fmtPatentCertDt = rowData.fmtPatentCertDt
              vm.patentCertificationFileData = response.data.resultData.patentCertificationFiles
              vm.patentThumbnailFileData = response.data.resultData.patentThumbnailFiles

            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
        )

        let patentManageWindow = this.$refs.patentRef.kendoWidget()
        patentManageWindow.center().open()
      },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //템플릿구분
        if (refs.patentGbnSrhRef !== undefined) {
          refs.patentGbnSrhRef.kendoWidget().select(0)
        }

        if (refs.lastMdfyDtRef !== undefined) {
          refs.lastMdfyDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.patentCertDtRef !== undefined) {
          refs.patentCertDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.lastMdfyIdRef !== undefined) {
          refs.lastMdfyIdRef.kendoWidget().value('')
        }

        if (refs.patentNmTxRef !== undefined) {
          refs.patentNmTxRef.kendoWidget().value('')
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onClickWindowClose() {
        this.$refs.patentRef.kendoWidget().close()
        this.$refs.patentListGridRef.kendoWidget().dataSource.read()
      },
      editModeUpdate(editMode) {
        const vm =this
        vm.editMode = editMode
      },

    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        patentRowData: {},
        patentCertificationFileData: {},
        patentThumbnailFileData: {},
        editMode: '',
        val: '',
        rgstDtRange: {start: undefined, end: undefined},
        patentCertDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: ''
          },
        },
        patentGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'patentYn',
            title: '구분',
            width: '7%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return dataItem.patentYn === 'Y'? '특허' : '인증'
            }
          },
          {
            field: 'fmtPatentCertDt',
            title: '취득 · 인증날짜',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'patentCertNoTx',
            title: '특허 · 인증번호',
            width: '16%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'patentCertNoTxEn',
            title: '특허 · 인증번호 (영문)',
            width: '16%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'patentNmTx',
            title: '특허 · 인증명',
            width: '50%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'patentNmTxEn',
            title: '특허 · 인증명 (영문)',
            width: '50%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'fmtLastMdfyDt',
            title: '작성일',
            width: '12%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'lastMdfyId',
            title: '작성자',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
        ],
      }
    },
  }
</script>
